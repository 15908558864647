<template>
    <div>
        <van-nav-bar :title="$t('loan_agreement')" left-arrow @click-left="onClickLeft" class="header" :fixed="true" />
        <!-- <div class="mainContent" :v-html="content"> </div> -->
        <iframe :src="content" frameborder="0" style="padding-top: 65px;padding-left: 5px; width:100vw;height:100vh;"></iframe>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            content: '',
            lang: localStorage.getItem("lang") || "en",
        }
    },
    created() {
        this.getinfo()
    },
    methods: {
        // 返回按钮
        onClickLeft() {
            this.$router.go(-1)
        },
        getinfo() {
            if (this.lang == "en") {
                this.contenten();
            } else if (this.lang == "ru") {
                this.contentru();
            } else if (this.lang == "zh") {
                this.contentzh();
            } else if (this.lang == "fr") {
                this.contentfr();
            } else if (this.lang == "it") {
                this.contentit();
            } else if (this.lang == "es") {
                this.contentes();
            }
        },
        contenten() {
            this.content = "/static/en.html"
        },
        contentru() {
            this.content = "/static/ru.html"
        },
        contentzh() {
            this.content = "/static/zh.html"
        },
        contentfr() {
            this.content = "/static/fr.html"
        },
        contentit() {
            this.content = "/static/it.html"
        },
        contentes() {
            this.content = "/static/es.html"
        }
    }
}
</script>
<style lang="less" scoped>
.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    margin-top: 56px;
    padding: 15px;
}
</style>
  